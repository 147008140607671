import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const BananesNotes = () => (
  <Layout>
    <SEO title="Bananes Citronnées suivies de CAPRICES" />
    <div className="table-responsive">
      <p>
        <span className="par-starting">On n'a pas à demander</span><br />
        aux poètes de séparer leurs oeu-<br />
        vres de leurs passions; celles-ci<br />
        sont la substance de celles-là.<br />
        BENDA
      </p>
      <p><br /><br /></p>
      <hr />
      <p><br /><br /></p>
      <p>
        <span className="par-starting">Le poème, plus que tout</span><br />
        autre texte, est toujours ouvert.<br />
        Chacun y perçoit des appels varia-<br />
        bles, des choix divers dans un champ<br />
        complexe des signes, dans une multi-<br />
        plicité incroyable de présences et <br />
        d'absences...<br /><br />
        <span className="par-starting">Ce language dans lequel la</span><br />
        norme habituelle ne trouve pas tou-<br />
        jours son compte...<br /><br />
        <span className="par-starting">C'est en le percevant in-</span><br />
        dividuellement, personnellement, que<br />
        je rends ce langage à la vie<br />
        V.Y. Mudimbe
      </p>
    </div>
    <Link to="/bananes-citronnees-preface/">Préface</Link>
  </Layout>
)

export default BananesNotes
